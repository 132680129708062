import { useState, useRef, useContext, useEffect } from 'react';
import { useReactToPrint } from 'react-to-print';

import { Form, Row, Col, Input, Button, 
  Select, InputNumber, Modal,
  Typography, Divider, Space, Checkbox, message } from 'antd';
import { ExclamationCircleOutlined, PrinterOutlined, SaveOutlined, ClearOutlined} from "@ant-design/icons";
import axios from 'axios';

import './style.css';
import usePrompt from '../../components/layout/usePrompt';
import { apiUrlBase } from '../../configs/constants';
import { mainAppContext } from '../../App';
import { BreakDownRecordType } from '../../types/breakDown';


/*
const txtMTC = `A MTC describes a situation in which benefits are not payable to replace a tooth extracted
              prior to having current dental coverage. Ask if consideration is given if tooth was
              textracted with patient’s prior insurance`;
*/

const defaultValues = {
  insRenewalMonth: '1',
  insAnnualMax: 1500,
  insAnnualUsed: 0,
  insDedInd: 50,
  insDedFam: 150,
  insDedMet: 'N',
  insPrevPercent: 100,
  insBasicPercent: 80,
  insMajorPercent: 50,
  insFillingBorM: 'Basic',
  insEndoBorM: 'Basic',
  insPerioBorM: 'Basic',
  insOsBorM: 'Basic',
  insExamFreqUnit: '# Per Yr',
  insProphyFreqUnit: '# Per Yr',
  insBWXFreqUnit: '# Per Yr',
  insPanFmxFreqUnit: 'Every # Yr',
  insSealantsPercent: 100,
  insSealantsFreqUnit: 'Every # Mo',
  insSealantsType: 'MOL',
  insFluoridePercent: 100,
  insFluorideFreqUnit: '# Per Yr',
  insEmerCoverage: 'Y',
  insEmerPercent: 100,
  insEmerDeductApplies: 'Y',
  insLoePercent: 100,
  insLoeDeductApplies: 'Y',
  insPaPercent: 100,
  insPaDeductApplies: 'Y',
  insPostCompDowngrade: 'N',
  insDsrpPercent: 100,
  insDsrpFreqUnit: 'Every # Mo',
  insDsrpAllSameDay: 'Y',
  insPerioPercent: 100,
  insPerioFreqUnit: '# Per Yr',
  insPerioDed: 'Y',
  insCoreBuildCoverage: 'Y',
  insCoreBuildPercent: 50,
  insCoreBuildFreqUnit: 'Every # Yr',
  insCrownCoverage: 'Y',
  insCrownPercent: 50,
  insCrownFreqUnit: 'Every # Yr',
  insMetalCrownOnMolar: 'Y',
  insImplantBodyCoverage: 'Y',
  insImplantBodyPercent: 50,
  insImplantBodyFreqUnit: '# Per Yr',
  insImplantAbutCoverage: 'Y',
  insImplantAbutPercent: 50,
  insImplantAbutFreqUnit: '# Per Yr',
  insImplantCrownCoverage: 'Y',
  insImplantCrownPercent: 50,
  insImplantCrownFreqUnit: '# Per Yr',
  insImplantCrownDowngrade: 'N',
  insMtcApplied: 'N',
  insWaitingPdRequired: 'N'
};

const freqOptions = [
  { value: 'Every # Mo', label: 'Every # Mo' },
  { value: 'Every # Yr', label: 'Every # Yr' },
  { value: '# Per Yr', label: '# Per Yr' },
  { value: '# Per Lf', label: '# Per Lf' },
  { value: 'No Freq', label: 'No Freq' },
];

const yesNoOptions = [
  { value: 'Y', label: 'Y' },
  { value: 'N', label: 'N' },
];

const basicMajorOptions = [
  { value: 'Basic', label: 'Basic' },
  { value: 'Major', label: 'Major' },
];

function BreakDownForm (props: any) {
  const componentRef = useRef(null);
  const {user} = useContext(mainAppContext);

  const config = {
    headers: {
      Authorization: `Bearer ${user}`,
      'Content-Type': 'application/json',
    },
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'Dental Break Down Form',
  });

  const [isDirty, setIsDirty] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);
  const [loadingSaveAs, setLoadingSaveAs] = useState(false);
  const [breakDown, setBreakDown] = useState<BreakDownRecordType>(props.breakDown);

  const [form] = Form.useForm();

  const handleFormChange = () => {
    setIsDirty(true); 
    props.setParentIsDirty(true);
  };

  const handleReset = () => {
    form.resetFields();
    form.setFieldsValue(defaultValues);
    const temp : BreakDownRecordType = { breakDowns: defaultValues};
    setBreakDown(temp);
    setIsDirty(false);
    props.setParentIsDirty(true);
  };

  const handleSave = async () => {
    const newBreakDowns = form.getFieldsValue();

    if (!newBreakDowns.patientName) {
      message.error('Please enter patient name.');
      return false;
    }

    if (!newBreakDowns.insuranceName) {
      message.error('Please enter insurance name.');
      return false;
    }

    if (!newBreakDowns.insGroupNo) {
      message.error('Please enter group#.');
      return false;
    }

    const postData : BreakDownRecordType = {
      ...newBreakDowns   
    };

    try {
      setLoadingSave(true);

      let apiUrl = `${apiUrlBase}/breakdowns`;
 
      if (breakDown?.id) {
        apiUrl =`${apiUrlBase}/breakdowns/${breakDown!.id}`;
      }

      let response = await axios.put(apiUrl, postData, config);
      const updatedId = response.data.split(" ").pop();

      postData.id = updatedId;
   
      setBreakDown(postData);
      setIsDirty(false);
      props.setParentIsDirty(false);
      props.refreshParent();
      setLoadingSave(false);

    } catch (error) {
      console.log(error)
      message.error('error occured while saving');
      setLoadingSave(false);
    }
  };

  const handleSaveAs = () => {

    const newBreakDowns = form.getFieldsValue();

    if (!newBreakDowns.patientName) {
      message.error('Please enter patient name.');
      return false;
    }

    if (!newBreakDowns.insuranceName) {
      message.error('Please enter insurance name.');
      return false;
    }

    if (!newBreakDowns.insGroupNo) {
      message.error('Please enter group#.');
      return false;
    }

    Modal.confirm({
      title: "Are you sure you want to create a new break down?",
      icon: <ExclamationCircleOutlined />,
      async onOk() {       
        const postData : BreakDownRecordType = {
          ...newBreakDowns,  
        };
    
        try {
          setLoadingSaveAs(true);
          const apiUrl = `${apiUrlBase}/breakdowns`;
          const response = await axios.put(apiUrl, postData, config);
          const updatedId = response.data.split(" ").pop();
    
          postData.id = updatedId;
          setBreakDown(postData);
    
          setIsDirty(false);
          props.setParentIsDirty(false);
          props.refreshParent();
          setLoadingSaveAs(false);
    
        } catch (error) {
          console.log(error)
          message.error('error occured while saving');
          setLoadingSaveAs(false);
        }
      },
    });
  };

  useEffect(() => {
    if (!props.breakDown) {
      form.setFieldsValue(defaultValues);
      const temp : BreakDownRecordType = { breakDowns: defaultValues};
      setBreakDown(temp);
      setIsDirty(false);
      props.setParentIsDirty(false);
    } else {
      form.setFieldsValue(props.breakDown.breakDowns);
      setBreakDown(props.breakDown);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, []);

  const promptMessage = "Are you sure you want to leave? You have unsaved changes.";
  usePrompt(promptMessage, isDirty);

  return (
    <>
    <div className="form-to-print" id='breakdown' ref={componentRef}>
      <Typography.Title level={5} className="center-title" > Break Down Sheet</Typography.Title>
      <Form 
        form={form} 
        size='small' 
        onValuesChange={handleFormChange} 
        colon={false} 
        initialValues={breakDown?.breakDowns}
      >
        <div className="form-inputs">
          <Row gutter={16}>
            <Col span={7}>
              <Form.Item label="Effective Date" name="effectiveStart" >
                <Input placeholder="Effective Date" />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="Renewal Month" name="insRenewalMonth" >
                <Select 
                    options={[
                      { value: '1', label: 'Jan' },
                      { value: '2', label: 'Feb' },
                      { value: '3', label: 'Mar' },
                      { value: '4', label: 'Apr' },
                      { value: '5', label: 'May' },
                      { value: '6', label: 'Jun' },
                      { value: '7', label: 'Jul' },
                      { value: '8', label: 'Aug' },
                      { value: '9', label: 'Sep' },
                      { value: '10', label: 'Oct' },
                      { value: '11', label: 'Nov' },
                      { value: '12', label: 'Dec' },
                    ]}
                  />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={8}>
              <Form.Item label="Patient Name" name="patientName" rules={[{ required: true, message: 'Please enter patient name' }]} >
                <Input placeholder="patient name" />
              </Form.Item>
            </Col>
            <Col span={7}>
              <Form.Item label="Date of Birth" name="patientDob" >
                <Input placeholder="patient date of birth" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={8}>
              <Form.Item label="Subscriber Name" name="subscriberName" >
                <Input placeholder="subscriber name" />
              </Form.Item>
            </Col>
            <Col span={9}>
              <Form.Item label="Subscriber Date of Birth" name="subscriberDob" >
                <Input placeholder="subscribe date of birth" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item label="Insurance Name" name="insuranceName" rules={[{ required: true, message: 'Please enter insurance name' }]}>
                <Input placeholder="insurance name" />
              </Form.Item>
            </Col>
            <Col span={7}>
              <Form.Item label="Insurance Phone #" name="insurancePhoneNo" rules={[{ pattern: /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/, message: 'Please enter a valid phone number' }]}>
                <Input placeholder="insurance phone number" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={6}>
              <Form.Item label="ID#" name="insuranceId" >
                <Input placeholder="insurance ID" />
              </Form.Item>
            </Col>
            <Col span={9}>
              <Form.Item label="Group Name" name="insGroupName" >
                <Input placeholder="insurance group name" />
              </Form.Item>
            </Col>
            <Col span={9}>
              <Form.Item label="Group #" name="insGroupNo" rules={[{ required: true, message: 'Please enter group#' }]} >
                <Input placeholder="insurance group# " />
              </Form.Item>
            </Col>
          </Row>

          <Row style={{ height: '15px' }}>
          <Form.Item > </Form.Item>
          </Row>

          <Row gutter={16}>
            <Col span={5}>
              <Form.Item label="Annual max" name="insAnnualMax" rules={[{pattern: /^[0-9]\d*(\.\d+)?$/, message: 'Please enter a valid number'}]}>
                <InputNumber
                  formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={(value) => value!.replace(/\$\s?|(,*)/g, '')}
                />
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item label="AMT used" name="insAnnualUsed" rules={[{pattern: /^[0-9]\d*(\.\d+)?$/, message: 'Please enter a valid number'}]}>
                <InputNumber
                  formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={(value) => value!.replace(/\$\s?|(,*)/g, '')}
                />
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item label="Deduct:Ind" name="insDedInd" rules={[{pattern: /^[0-9]\d*(\.\d+)?$/, message: 'Please enter a valid number'}]}>
              <InputNumber
                  formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={(value) => value!.replace(/\$\s?|(,*)/g, '')}
                />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label="FAM" name="insDedFam" rules={[{pattern: /^[0-9]\d*(\.\d+)?$/, message: 'Please enter a valid number'}]}>
                <InputNumber
                  formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={(value) => value!.replace(/\$\s?|(,*)/g, '')}
                />
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item label="Deduct. Met" name="insDedMet" >
                <Select 
                  options={yesNoOptions}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row style={{ height: '15px' }}>
          <Form.Item > </Form.Item>
          </Row>

          <Row gutter={16} justify="center">
            <Col span={5}>
              <Form.Item label="Preventive" name="insPrevPercent" >
                <InputNumber
                  min={0}
                  max={100} 
                  formatter={(value) => `${value}%`}
                  // @ts-expect-error
                  parser={(value) => value!.replace('%', '')}
                />
              </Form.Item>
            </Col>
            <Col span={5}>
            <Form.Item label="Basic" name="insBasicPercent" >
                <InputNumber 
                  min={0}
                  max={100} 
                  formatter={(value) => `${value}%`}
                  // @ts-expect-error
                  parser={(value) => value!.replace('%', '')}
                />
              </Form.Item>
            </Col>
            <Col span={5}>
            <Form.Item label="Major" name="insMajorPercent" >
                <InputNumber 
                  min={0}
                  max={100} 
                  formatter={(value) => `${value}%`}
                  // @ts-expect-error
                  parser={(value) => value!.replace('%', '')}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row style={{ height: '15px' }}>
          <Form.Item > </Form.Item>
          </Row>

          <Row gutter={16}>
            <Col span={6.5}>
              <Form.Item label="Filling (2230/2391)" name="insFillingBorM" >
                <Select 
                  options={basicMajorOptions}
                />
              </Form.Item>
            </Col>
            <Col span={5.5}>
              <Form.Item label="Endo (3330)" name="insEndoBorM" >
                <Select 
                  options={basicMajorOptions}
                />
              </Form.Item>
            </Col>
            <Col span={6.5}>
              <Form.Item label="Perio (4341)" name="insPerioBorM" >
              <Select 
                  options={basicMajorOptions}
                />
              </Form.Item>
            </Col>
            <Col span={5.5}>
              <Form.Item label="OS (7140/7210)" name="insOsBorM" >
                <Select 
                  options={basicMajorOptions}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row style={{ height: '15px' }}>
          <Form.Item > </Form.Item>
          </Row>
          <Row gutter={16} justify="end">
            <Col span={14}>
              <Form.Item label="Family" name="familyMember" >
                <Input placeholder="family member " />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={6}>
              <Form.Item label="(0150/0120) Exam" name="insExamFreq" rules={[{pattern: /^[1-9]\d*(\.\d+)?$/, message: 'Please enter a valid number'}]}>
                <Input placeholder="freq" />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label="" name="insExamFreqUnit" >
                <Select 
                  options={freqOptions}
                />
              </Form.Item>
            </Col>
            <Col span={14}>
              <Form.Item label="History" name="insExamHist" >
                <Input placeholder="no exam history" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={6}>
              <Form.Item label="(1110/1120) Prophy" name="insProphyFreq" rules={[{pattern: /^[1-9]\d*(\.\d+)?$/, message: 'Please enter a valid number'}]}>
                <Input placeholder="freq" />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label="" name="insProphyFreqUnit" >
                <Select 
                  options={freqOptions}
                />
              </Form.Item>
            </Col>
            <Col span={14}>
              <Form.Item label="History" name="insProphyHist" >
                <Input placeholder="no prophy history" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={6}>
              <Form.Item label="(0272/0274) BWX" name="insBWXFreq" rules={[{pattern: /^[1-9]\d*(\.\d+)?$/, message: 'Please enter a valid number'}]}>
                <Input placeholder="freq" />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label="" name="insBWXFreqUnit" >
                <Select 
                  options={freqOptions}
                />
              </Form.Item>
            </Col>
            <Col span={14}>
              <Form.Item label="History" name="insBWXHist" >
                <Input placeholder="no BWX history" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={6}>
              <Form.Item label="(0210/0330) PAN/FMX" name="insPanFmxFreq" rules={[{pattern: /^[1-9]\d*(\.\d+)?$/, message: 'Please enter a valid number'}]}>
                <Input placeholder="freq" />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label="" name="insPanFmxFreqUnit" >
                <Select 
                  options={freqOptions}
                />
              </Form.Item>
            </Col>
            <Col span={14}>
              <Form.Item label="History" name="insPanFmxHist" >
                <Input placeholder="no PAN/FMX history" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={5}>
              <Form.Item label="(1351) Sealants" name="insSealantsAge" rules={[{pattern: /^[1-9]\d*(\.\d+)?$/, message: 'Please enter a valid number'}]}>
                <Input placeholder="age" />
              </Form.Item>
            </Col>
            <Col span={3}>
              <Form.Item label="@" name="insSealantsPercent" >
                <InputNumber
                  min={0}
                  max={100} 
                  formatter={(value) => `${value}%`}
                  // @ts-expect-error
                  parser={(value) => value!.replace('%', '')}
                />
              </Form.Item>
            </Col>
            <Col span={3}>
              <Form.Item label="Freq" name="insSealantsFreq" rules={[{pattern: /^[1-9]\d*(\.\d+)?$/, message: 'Please enter a valid number'}]}>
                <Input placeholder="freq" />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label="" name="insSealantsFreqUnit" >
              <Select 
                  options={freqOptions}
                />
              </Form.Item>
            </Col>
            <Col span={3}>
              <Form.Item label="" name="insSealantsType" >
                <Select 
                  options={[
                    { value: 'MOL', label: 'MOL' },
                    { value: 'ALL POST', label: 'ALL POST' },
                  ]}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="History" name="insSealantsHist" >
                <Input placeholder="no fluoride history" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={6}>
              <Form.Item label="(1206/1208) Fluoride" name="insFluorideAge" rules={[{pattern: /^[1-9]\d*(\.\d+)?$/, message: 'Please enter a valid number'}]}>
                <Input placeholder="age" />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label="@" name="insFluoridePercent" >
                <InputNumber
                  min={0}
                  max={100} 
                  formatter={(value) => `${value}%`}
                  // @ts-expect-error
                  parser={(value) => value!.replace('%', '')}
                />
              </Form.Item>
            </Col>
            <Col span={3}>
              <Form.Item label="Freq" name="insFluorideFreq" rules={[{pattern: /^[1-9]\d*(\.\d+)?$/, message: 'Please enter a valid number'}]}>
                <Input placeholder="freq" />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label="" name="insFluorideFreqUnit" >
                <Select 
                  options={freqOptions}
                />
              </Form.Item>
            </Col>
            <Col span={7}>
              <Form.Item label="History" name="insFluorideHist" >
                <Input placeholder="no fluoride history" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={5}>
              <Form.Item label="(9110) EMER" name="insEmerCoverage" >
                <Select 
                  options={yesNoOptions}
                />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label="@" name="insEmerPercent" >
                <InputNumber
                  min={0}
                  max={100} 
                  formatter={(value) => `${value}%`}
                  // @ts-expect-error
                  parser={(value) => value!.replace('%', '')}
                />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label="Freq" name="insEmerFreq" >
                <Input placeholder="freq" />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="Deduct. Applies" name="insEmerDeductApplies" >
                <Select 
                  options={yesNoOptions}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={6}>
              <Form.Item label="(0140) LOE @" name="insLoePercent" >
                <InputNumber
                  min={0}
                  max={100} 
                  formatter={(value) => `${value}%`}
                  // @ts-expect-error
                  parser={(value) => value!.replace('%', '')}
                />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label="Freq" name="insLoeFreq" >
                <Input placeholder="freq" />
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item label="Deduct. Applies" name="insLoeDeductApplies" >
                <Select 
                  options={yesNoOptions}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={6}>
              <Form.Item label="(0220/0230) PA @" name="insPaPercent" >
                <InputNumber
                  min={0}
                  max={100} 
                  formatter={(value) => `${value}%`}
                  // @ts-expect-error
                  parser={(value) => value!.replace('%', '')}
                />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label="Freq" name="insPaFreq" >
                <Input placeholder="freq" />
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item label="Deduct. Applies" name="insPaDeductApplies" >
                <Select 
                  options={yesNoOptions}
                />
              </Form.Item>
            </Col>
          </Row>
        
          <Row style={{ height: '15px' }}>
          <Form.Item > </Form.Item>
          </Row>
          <Row gutter={16}>
            <Col span={7}>
              <Form.Item label="Post. Composite Downgrade" name="insPostCompDowngrade" >
                <Select 
                  options={yesNoOptions}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item name="postCompositDowngradeOptions">  
                <Checkbox.Group options={['Molars', 'Premolars']} />
              </Form.Item>
            </Col>             
            <Col span={4}>
              <Form.Item label="Freq" name="insPostCompFreq" >
                <Input placeholder="freq" />
              </Form.Item>
            </Col>
          </Row>
          <Row style={{ height: '15px' }}>
          <Form.Item > </Form.Item>
          </Row>
          <Row gutter={16}>
            <Col span={6}>
              <Form.Item label="(4341) DSRP @" name="insDsrpPercent" >
                <InputNumber
                  min={0}
                  max={100} 
                  formatter={(value) => `${value}%`}
                  // @ts-expect-error
                  parser={(value) => value!.replace('%', '')}
                />
              </Form.Item>
            </Col>
            <Col span={3}>
              <Form.Item label="Freq" name="insDsrpFreq" rules={[{pattern: /^[1-9]\d*(\.\d+)?$/, message: 'Please enter a valid number'}]}>
                <Input placeholder="freq" />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label="" name="insDsrpFreqUnit" >
                <Select 
                  options={freqOptions}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="All Quads Same Day" name="insDsrpAllSameDay" >
                <Select 
                  options={yesNoOptions}
                />
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item label="History" name="insDsrpHist" >
                <Input placeholder="no DSRP history" />
              </Form.Item>
            </Col>
          </Row>
          <Row style={{ height: '15px' }}>
          <Form.Item > </Form.Item>
          </Row>
          <Row gutter={16}>
            <Col span={6}>
              <Form.Item label="(4910) Perio Maint @" name="insPerioPercent" >
                <InputNumber
                  min={0}
                  max={100} 
                  formatter={(value) => `${value}%`}
                  // @ts-expect-error
                  parser={(value) => value!.replace('%', '')}
                />
              </Form.Item>
            </Col>
            <Col span={3}>
              <Form.Item label="Freq" name="insPerioFreq" rules={[{pattern: /^[1-9]\d*(\.\d+)?$/, message: 'Please enter a valid number'}]}>
                <Input placeholder="freq" />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label="" name="insPerioFreqUnit" >
                <Select 
                  options={freqOptions}
                />
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item label="Deduct. Applies" name="insPerioDed" >
                <Select 
                  options={yesNoOptions}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="History" name="insPerioHist" >
                <Input placeholder="no perio history" />
              </Form.Item>
            </Col>
          </Row>
          <Row style={{ height: '15px' }}>
          <Form.Item > </Form.Item>
          </Row>
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item label="Core Buildup (2950)/Post (2954)" name="insCoreBuildCoverage" >
                <Select 
                  options={yesNoOptions}
                />
              </Form.Item>
            </Col>
            <Col span={3}>
              <Form.Item label="@" name="insCoreBuildPercent" >
                <InputNumber
                  min={0}
                  max={100} 
                  formatter={(value) => `${value}%`}
                  // @ts-expect-error
                  parser={(value) => value!.replace('%', '')}
                />
              </Form.Item>
            </Col>
            <Col span={3}>
              <Form.Item label="Freq" name="insCoreBuildFreq" rules={[{pattern: /^[1-9]\d*(\.\d+)?$/, message: 'Please enter a valid number'}]}>
                <Input placeholder="freq" />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label="" name="insCoreBuildFreqUnit" >
                <Select 
                  options={freqOptions}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={5}>
              <Form.Item label="Crown (2740/2750)" name="insCrownCoverage" >
                <Select 
                  options={yesNoOptions}
                />
              </Form.Item>
            </Col>
            <Col span={3}>
              <Form.Item label="@" name="insCrownPercent" >
                <InputNumber
                  min={0}
                  max={100} 
                  formatter={(value) => `${value}%`}
                  // @ts-expect-error
                  parser={(value) => value!.replace('%', '')}
                />
              </Form.Item>
            </Col>
            <Col span={3}>
              <Form.Item label="Freq" name="insCrownFreq" rules={[{pattern: /^[1-9]\d*(\.\d+)?$/, message: 'Please enter a valid number'}]}>
                <Input placeholder="freq" />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label="" name="insCrownFreqUnit" >
                <Select 
                  options={freqOptions}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}> 
            <Col span={9}>
              <Form.Item label="(2740) Porcelain Crown Downgrade to" name="insPorcelainCrownDowngrade" >
                <Input placeholder="code" />
              </Form.Item>
            </Col>
            <Col span={7}>
              <Form.Item label="Metal Crown on Molar Teeth?" name="insMetalCrownOnMolar" >
                <Select 
                  options={yesNoOptions}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row style={{ height: '15px' }}>
          <Form.Item > </Form.Item>
          </Row>
          <Row gutter={16}>
            <Col span={7}>
              <Form.Item label="Implant Body (6010)" name="insImplantBodyCoverage" >
                <Select 
                  options={yesNoOptions}
                />
              </Form.Item>
            </Col>
            <Col span={3}>
              <Form.Item label="@" name="insImplantBodyPercent" >
                <InputNumber
                  min={0}
                  max={100} 
                  formatter={(value) => `${value}%`}
                  // @ts-expect-error
                  parser={(value) => value!.replace('%', '')}
                />
              </Form.Item>
            </Col>
            <Col span={3}>
              <Form.Item label="Freq" name="insImplantBodyFreq" rules={[{pattern: /^[1-9]\d*(\.\d+)?$/, message: 'Please enter a valid number'}]}>
                <Input placeholder="freq" />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label="" name="insImplantBodyFreqUnit" >
                <Select 
                  options={freqOptions}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={7}>
              <Form.Item label="Implant Abutment (6057)" name="insImplantAbutCoverage" >
                <Select 
                  options={yesNoOptions}
                />
              </Form.Item>
            </Col>
            <Col span={3}>
              <Form.Item label="@" name="insImplantAbutPercent" >
                <InputNumber
                  min={0}
                  max={100} 
                  formatter={(value) => `${value}%`}
                  // @ts-expect-error
                  parser={(value) => value!.replace('%', '')}
                />
              </Form.Item>
            </Col>
            <Col span={3}>
              <Form.Item label="Freq" name="insImplantAbutFreq" rules={[{pattern: /^[1-9]\d*(\.\d+)?$/, message: 'Please enter a valid number'}]}>
                <Input placeholder="freq" />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label="" name="insImplantAbutFreqUnit" >
                <Select 
                  options={freqOptions}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={6}>
              <Form.Item label="Implant Crown (6058)" name="insImplantCrownCoverage" >
                <Select
                  options={yesNoOptions}
                />
              </Form.Item>
            </Col>
            <Col span={3}>
              <Form.Item label="@" name="insImplantCrownPercent" >
                <InputNumber
                  min={0}
                  max={100} 
                  formatter={(value) => `${value}%`}
                  // @ts-expect-error
                  parser={(value) => value!.replace('%', '')}
                />
              </Form.Item>
            </Col>
            <Col span={3}>
              <Form.Item label="Freq" name="insImplantCrownFreq" rules={[{pattern: /^[1-9]\d*(\.\d+)?$/, message: 'Please enter a valid number'}]}>
                <Input placeholder="freq" />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label="" name="insImplantCrownFreqUnit" >
                <Select 
                  options={freqOptions}
                />
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item label="Downgraded?" name="insImplantCrownDowngrade" >
                <Select 
                  options={yesNoOptions}
                />
              </Form.Item>
            </Col>
            <Col span={3}>
              <Form.Item label=" to" name="inImplantCrownDowngardeTo" >
                <Input placeholder="code" />
              </Form.Item>
            </Col>
          </Row>
          <Row style={{ height: '15px' }}>
          <Form.Item > </Form.Item>
          </Row>
          <Row gutter={16}>
            <Col span={9}>
              <Form.Item label="Is Missing Teeth Clause Applied (MTC)?" name="insMtcApplied" >
                <Select 
                  options={yesNoOptions}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row style={{ height: '15px' }}>
          <Form.Item > </Form.Item>
          </Row>
          <Row gutter={16}>
            <Col span={7}>
              <Form.Item label="Waiting Period Required?" name="insWaitingPdRequired" >
                <Select 
                  options={yesNoOptions}
                />
              </Form.Item>
            </Col>
            <Col span={3}>
              <Form.Item name="insWaitingPdLength">
                <Input  placeholder="waiting period" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Fee Schedule" name="insFeeSchedule">
                <Input  placeholder="fee schedule" />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label="Payor ID" name="insPayorId">
                <Input  placeholder="payor ID" />
              </Form.Item>
            </Col>
          </Row>
          <Row style={{ height: '15px' }}>
          <Form.Item > </Form.Item>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item label="Claim Address" name="insClaimAddress" >
                <Input placeholder="claim address" />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item label="Restoration History" name="insRestorationHist" >
                <Input.TextArea placeholder="restoration history" rows={2}/>
              </Form.Item>
            </Col>
          </Row>
        </div>
      </Form>
    </div>
    <Divider />
    <Space className="breakdown-buttons">
      {true && <Button type="primary" ghost onClick={handleReset} icon={<ClearOutlined />}>
          Reset
        </Button>}
      {true && 
        <Button type="primary" ghost onClick={handleSave} loading={loadingSave} disabled={!isDirty} icon={<SaveOutlined />}>
          Save
        </Button>}
      {breakDown?.id &&
        <Button type="primary" ghost onClick={handleSaveAs} loading={loadingSaveAs} icon={<SaveOutlined />}>
          Save As
        </Button>}
      <Button type="primary" ghost onClick={handlePrint} icon={<PrinterOutlined />}>
        Print
      </Button>
    </Space>
    </>
  );
}

export default BreakDownForm;
